import React from 'react';
import Layout from '../components/global/layout';
import SEO from '../components/seo';
import PageBanner from '../components/layouts/page-banner';
import { Container } from '@material-ui/core';
import LightMode from '../components/global/light-mode';
import VerticalPadding from '../components/global/vertical-padding';

const TermsOfUse: React.FC = () => {
  return (
    <Layout>
      <SEO
        title="Terms of Use"
        description="Marvel &amp; Snap will not have any liability or responsibility for the content of any communications you post to this website, or for any errors or violations of any laws or regulations by you."
      />
      <PageBanner heading="Terms of Use" />
      <LightMode>
        <VerticalPadding>
          <Container maxWidth="lg" style={{ lineHeight: 1.77 }}>
            <h2>1. Limited Right to Use.</h2>
            <p>
              This web site is owned and operated by Marvel &amp; Snap. Unless
              otherwise specified, all Materials on this web site are the
              property of Marvel &amp; Snap and are protected by the copyright
              laws of Canada and, throughout the world by the applicable
              copyright laws. You may, view, print and/or download one copy of
              the Materials from this web site on any single computer solely for
              your personal, informational, non-commercial use, provided you
              keep intact all copyright and other proprietary notices. No
              Materials published by Marvel &amp; Snap on this web site, in
              whole or in part, may be copied, reproduced, modified,
              republished, uploaded, posted, transmitted, or distributed in any
              form or by any means without prior written permission from Marvel
              &amp; Snap. The use of any such Materials on any other web site or
              networked computer environment or for any other purpose is
              strictly prohibited and such unauthorized use may violate
              copyright, trademark and other similar laws.
            </p>
            <h2>2. Communications.</h2>
            <p>
              Except for any disclosure by you for technical support purposes,
              or as specified in our Privacy Statement, all communications from
              you to this web site will be considered non-confidential and
              non-proprietary. You agree that any and all comments, information,
              feedback and ideas regarding our company, products or services
              that you communicate to Marvel &amp; Snap (&#8220;Feedback&#8221;)
              will be deemed, at the time of communication to Marvel &amp; Snap,
              the property of Marvel &amp; Snap, and Marvel &amp; Snap shall be
              entitled to full rights of ownership, including without
              limitation, unrestricted right to use or disclose such Feedback in
              any form, medium or technology now known or later developed, and
              for any purpose, commercial or otherwise, without compensation to
              you. You are solely responsible for the content of your
              communications and their legality under all laws and regulations.
              You agree not to use this web site to distribute, link to or
              solicit content that is defamatory, harassing, unlawful,
              libellous, harmful to minors, threatening, obscene, false,
              misleading, or infringing a third party intellectual or privacy
              rights.
            </p>
            <h2>3. Access to Password Protected or Secured Areas.</h2>
            <p>
              Access to and use of password protected or secured areas of this
              web site is restricted to authorized users only. You will be asked
              to provide accurate and current information on all registration
              forms on this web site. You are solely responsible for maintaining
              the confidentiality of any username and password that you choose
              or is chosen by your web administrator on your behalf, to access
              this web site as well as any activity that occur under your
              username/password. You will not misuse or share your username or
              password, misrepresent your identity or your affiliation with an
              entity, impersonate any person or entity, or misstate the origin
              of any Materials you are exposed to through this web site.
            </p>
            <h2>4. Monitoring.</h2>
            <p>
              Although Marvel &amp; Snap is not obligated to do so, it will have
              the right to review your communications on this web site to
              determine whether you comply with our Terms of Use. Marvel &amp;
              Snap will not have any liability or responsibility for the content
              of any communications you post to this web site, or for any errors
              or violations of any laws or regulations by you. Marvel &amp; Snap
              will comply with any court order in disclosing the identity of any
              person posting communications on this web site. It is advisable
              that you review our <a href="/privacy-policy/">Privacy Policy</a>{' '}
              before posting any such communications. Please note that when you
              conduct transactions with other companies providing content via
              this web site, you will also be subject to their privacy policies.
            </p>
            <h2>5. Links to Other Sites.</h2>
            <p>
              The linked sites are not under the control of Marvel &amp; Snap
              and Marvel &amp; Snap is not responsible for the content of any
              linked site or any link contained in a linked site. Marvel &amp;
              Snap reserves the right to terminate any link at any time. Marvel
              &amp; Snap may provide links from this web site to other sites as
              a convenience to you and in no way should this be interpreted as
              an endorsement of any company, content or products to which it
              links. If you decide to access any of the third party sites linked
              to this web site, you do this entirely at your own risk. Marvel
              &amp; Snap DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED,
              TO ANY SUCH LINKED SITES, INCLUDING BUT NOT LIMITED TO ANY TERMS
              AS TO THE ACCURACY, OWNERSHIP, VALIDITY OR LEGALITY OF ANY CONTENT
              OF A LINKED SITE.
            </p>
            <h2>6. Trademarks.</h2>
            <p>
              The trademarks, service marks and logos of Marvel &amp; Snap and
              others used in this web site (&#8220;Trademarks&#8221;) are the
              property of Marvel &amp; Snap and their respective owners. You
              have no right to use any such Trademarks, and nothing contained in
              this web site or the Terms of Use grants any right to use (by
              implication, waiver, estoppel or otherwise) any Trademarks without
              the prior written permission of Marvel &amp; Snap or the
              respective owner.
            </p>
            <h2>7. Indemnity.</h2>
            <p>
              You agree to indemnify, defend and hold Marvel &amp; Snap harmless
              from and against any and all third party claims, liabilities,
              damages, losses or expenses (including reasonable attorney&#8217;s
              fees and costs) arising out of, based on or in connection with
              your access and/or use of this web site.
            </p>
            <h2>8. Limitation of Liability.</h2>
            <p>
              IN NO EVENT SHALL Marvel &amp; Snap OR ITS SUPPLIERS BE LIABLE FOR
              ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES
              INCLUDING, WITHOUT LIMITATION, LOSS PROFITS OR REVENUES, COSTS OF
              REPLACEMENT GOODS, LOSS OR DAMAGE TO DATA ARISING OUT OF THE USE
              OR INABILITY TO USE THIS WEB SITE OR ANY LINKED SITE, DAMAGES
              RESULTING FROM USE OF OR RELIANCE ON THE INFORMATION OR MATERIALS
              PRESENTED ON THIS WEB SITE, WHETHER BASED ON WARRANTY, CONTRACT,
              TORT OR ANY OTHER LEGAL THEORY EVEN IF MARVEL &amp; SNAP OR ITS
              SUPPLIERS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </p>
            <h2>9. Disclaimer.</h2>
            <p>
              Marvel &amp; Snap assumes no responsibility for accuracy,
              correctness, timeliness, or content of the Materials provided on
              this web site. You should not assume that the Materials on this
              web site are continuously updated or otherwise contain current
              information. Marvel &amp; Snap is not responsible for supplying
              content or materials from the web site that have expired or have
              been removed. THE MATERIALS PROVIDED AT THIS WEB SITE ARE PROVIDED
              &#8220;AS IS&#8221; AND ANY WARRANTY (EXPRESS OR IMPLIED),
              CONDITION OR OTHER TERM OF ANY KIND, INCLUDING WITHOUT LIMITATION,
              ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              NON-INFRINGEMENT OR TITLE IS HEREBY EXCLUDED.
            </p>
            <h2>10. Applicable Laws.</h2>
            <p>
              These Terms of Use are governed by the law in force in the
              Province of Ontario, Canada, and the parties irrevocably submit to
              the non-exclusive jurisdiction of the courts of Ontario and courts
              of appeal from them for determining any dispute concerning the
              Terms of Use.
            </p>
            <h2>11. General.</h2>
            <p>
              If you have any questions regarding the Terms of Use, please
              contact Marvel &amp; Snap via our{' '}
              <a href="/contact/">contact page</a>.
            </p>
          </Container>
        </VerticalPadding>
      </LightMode>
    </Layout>
  );
};

export default TermsOfUse;
